<template>
  <div>
    <Breadcrumbs :title="$tc('models.message.entity', 2)"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col call-chat-sidebar col-sm-12">
          <div class="card">
            <div class="card-body chat-body">
              <div class="chat-box h-100">
                <div class="chat-left-aside people-list h-100">
                  <div class="clearfix p-0 d-flex align-items-center total-unread">
                    <div class="about">
                      <div class="name">{{ $t('views.constants.totalUnread') }} {{ totalUnread }}</div>
                    </div>
                  </div>
                  <div class="search chat-search-container h-100 m-b-20">
                    <div class="form-group h-100">
                      <input v-model="search" v-on:keyup="searchMessages"  
                            class="form-control h-100" type="text"
                            :placeholder="$t('views.constants.search')"><i class="fa fa-search"></i>
                    </div>
                  </div>
                  <div class="people-list custom-scrollbar" id="messages-list">
                    <div :class="['master-link', 'message-container', 'w-100',
                                  'm-b-20', 'p-10', 'd-flex', message.unreadCount > 0 ? 'has-unread' : '',
                          message.id === currentMessage.id ? 'selected-message' : '']"
                          v-for="(message, index)
                          in messages.items" :key="index" @click="updateCurrentMessage(index)">
                      <div class="about flex-grow-1">
                        <div class="name">{{ message.sender.name }}</div>
                        <div class="status">{{ message.lastReply.createdAt }}</div>
                        <div class="status last-reply-container" >{{ truncate(message.lastReply.text, 52) }}</div>
                      </div>
                      <div v-if="message.unreadCount > 0">
                        <span class="badge badge-pill badge-primary unread-count"> {{ message.unreadCount }} </span>
                      </div>
                    </div>
                    <div v-if="messagesLoading" class="text-center">
                      <i class="fa fa-spinner fa-spin f-30"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="replies.items.length > 0 && currentMessage.sender" class="col call-chat-body">
          <div class="card">
            <div class="card-body p-0 chat">
              <div class="chat-box chat-body">
                <!-- Chat right side start-->
                <div class="col pr-10 pl-10 chat-right-aside h-100">
                  <!-- chat start-->
                  <div class="chat h-100">
                    <!-- chat-header start-->
                    <div class="chat-header clearfix p-0 d-flex align-items-center p-l-10">
                      <div class="about">
                        <div class="name">{{ currentMessage.sender.name }}</div>
                      </div>
                    </div>
                    <!-- chat-header end-->
                    <!-- chat history -->
                    <div class="chat-history chat-msg-box custom-scrollbar p-0" id="thread-list">
                      <div v-if="threadLoading" class="text-center">
                        <i class="fa fa-spinner fa-spin f-30"></i>
                      </div>
                      <ul class="thread-messages w-100 p-t-20">
                        <li  v-for="(chat, index) in replies.items.slice().reverse()" :key="index"
                            class="clearfix">
                          <div :class="messageClasses(chat)">
                            <div class="message-data " v-bind:class="{ 'text-right' : chat.sentByUser()}" >
                              <span class="message-data-time">{{ chat.createdAt }}</span>
                            </div>
                            {{ chat.text }}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- text container -->
                    <div class="clearfix message-input-container">
                      <div class="row m-0">
                        <div class="w-100">
                          <div class="input-group text-box">
                            <input class="form-control input-txt-bx" id="message-to-send" v-model="text"
                            v-on:keyup.enter="sendMessage"  type="text" 
                            name="message-to-send" :placeholder="$t('views.constants.typeMessage')">
                            <div class="input-group-append">
                              <button @click="sendMessage" class="btn btn-primary"
                              type="button">{{ $t('views.constants.send') }}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name:'MessageList',
  data() {
    return {
      messageOptions: {
        itemsPerPage: 20
      },
      threadOptions: {
        itemsPerPage: 10
      },
      pages: 0,
      search: '',
      messagesLoading: true,
      currentMessage: {},
      text: '',
      currentChat: {},
      threadLoading: true
    };
  },
  computed: mapGetters(['messages', 'replies', 'totalUnread']),
  methods: {
    ...mapActions(['getMessages', 'getReplies', 'sendReply', 'getTotalUnread']),
    searchMessages() {
      this.getMessages({...this.messageOptions, filterBy: {key: this.search}});
    },
    messagesScroll() {
      let scrollTop = document.getElementById('messages-list').scrollTop ;
      let clientHeight = document.getElementById('messages-list').clientHeight;
      let scrollHeight = document.getElementById('messages-list').scrollHeight;
      if(scrollTop + clientHeight >= scrollHeight) {
        this.messageOptions.itemsPerPage = this.messageOptions.itemsPerPage + 10;
        this.searchMessages();
      }
    },
    threadScroll() {
      let scrollTop = document.getElementById('thread-list').scrollTop;
      if(scrollTop === 0) {
        this.threadOptions.itemsPerPage = this.threadOptions.itemsPerPage + 10;
        this.getCurrentThread(false);
      }
    },
    updateCurrentMessage(index) {
      this.currentMessage = this.messages.items[index];
      this.messages.items[index].unreadCount = 0;
      this.getCurrentThread(true);
    },
    sendMessage() {
      if(this.text.length) {
        let message = this.text;
        this.sendReply({id: this.currentMessage.id, text: message}).then(() => {
          this.searchMessages();
          this.getCurrentThread(true);
        });
      }
    },
    getCurrentThread(scrollBottom) {
      this.getReplies({...this.threadOptions, id: this.currentMessage.id}).then(() => {
        let chatContainer = document.getElementById('thread-list');
        if (scrollBottom) {
          chatContainer.scrollTop = chatContainer.scrollHeight;
          this.text = '';
        } else {
          chatContainer.scrollTop = chatContainer.clientHeight;
        }
        this.setScrollListener();
        this.getTotalUnread();
      });
    },
    messageClasses(message) {
      let classes = ['message'];
      if (message.sentByUser()) {
        classes.push('my-message');
        if (localStorage.locale === 'ar') {
          classes.push('pull-right');
        }
      } else {
        classes.push('other-message');
        if (localStorage.locale === 'en') {
          classes.push('pull-right');
        }
      }
      return classes;
    },
    setScrollListener() {
      document.getElementById('thread-list').removeEventListener('scroll', this.threadScroll);
      if (this.replies.totalCount > this.replies.items.length) {
        document.getElementById('thread-list').addEventListener('scroll', this.threadScroll);
      } else {
        this.threadLoading = false;
      }
    }
  },
  mounted() {
    this.searchMessages();
  },
  watch: {
    messages() {
      document.getElementById('messages-list').removeEventListener('scroll', this.messagesScroll);
      if (this.messages.totalCount > this.messages.items.length) {
        document.getElementById('messages-list').addEventListener('scroll', this.messagesScroll);
      } else {
        this.messagesLoading = false;
      }
    },
    search() {
      this.messageOptions.itemsPerPage = 10;
    }
  }
};
</script>

<style scoped>

.about {
  padding: 0 !important;
}

.last-reply-container {
  height: 40px;
}

.unread-count {
  width: 20px;
  height: 20px;
}

.chat-right-aside {
  max-width: 100%;
}

.selected-message {
  background-color: #00000005;
}

.message-container {
  border-radius: 10px;
}

.chat-body {
  height: calc(100vh - 180px) !important;
}

.chat-search-container {
  height:35px !important;
}

.chat-header {
  height: 50px;
}

.message-input-container {
  height: 50px !important;
}

#thread-list {
  height: calc(100% - 100px) !important;
}

.message {
  width: 50% !important;
}

.has-unread {
  border: solid 1px #4466f2
}

.total-unread {
  height: 50px;
}

#messages-list {
  height: calc(100% - 100px);
}
</style>
